import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import { YMInitializer } from 'react-yandex-metrika';

import API from '../../api';
import { yaEvent } from '../../helper/metrika';

import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { useLinkToApp } from '../../helper/hooks';

import Seo from '../../components/seo';
import RequestModal from '../../components/request-modal/requestModal';

import Logo from '../../assets/icons/logo.svg';
import LogoWhite from '../../assets/icons/logo-white.svg';
import LogoRabotaru from '../../assets/icons/logo-rabotaru.svg';
import LogoSeparator from '../../assets/icons/logo-separator.svg';

import { isProduction, isStaging } from '../../helper/system';

import * as s from '../../styles/auth-rabotaru.module.scss';

const RabotaruAuth = () => {
  const [footerBlockHeight, setFooterBlockHeight] = useState({
    contacts: 'auto',
    links: 'auto'
  });

  function onResize () {
    if (document.documentElement.clientWidth < 480) {
      setFooterBlockHeight({
        contacts: 0,
        links: 0
      });
    } else {
      setFooterBlockHeight({
        contacts: 'auto',
        links: 'auto'
      });
    }
  }

  useEffect(() => {
    onMounted();
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const toggle = key => {
    setFooterBlockHeight({
      ...footerBlockHeight,
      [key]: footerBlockHeight[key] === 0 ? 'auto' : 0
    });
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: s.heroSliderDots,
    appendDots: dots => {
      return (
        <ul>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                className={cn(s.heroSliderDot, { [s.heroSliderDotActive]: index === currentSlide })}
              >
                {item.props.children}
              </li>
            );
          })}
        </ul>
      );
    },
    beforeChange: (_, next) => {
      setCurrentSlide(next);
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  const { linkToApp } = useLinkToApp();
  const linkToSignIn = `${linkToApp}/sign-in?auth-rabotaru=1`;

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    yaEvent('LANDING-AUTH_CLICK_APPLICATION');
    API.metric.dispatchEvent({
      event_id: 'LANDING-AUTH_CLICK_APPLICATION'
    });

    setIsOpen(true);
  };

  const onMounted = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'AUTH-LANDING_SHOW_PAGE'
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onRegistrationBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'AUTH-LANDING_CLICK_CREATE-ACCOUNT'
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={s.authPage}>
      <YMInitializer
        accounts={[isProduction && !isStaging ? 64633693 : 66598438]}
      />

      <Seo
        title="Авторизация в сервисе СберПодбор для клиентов Работа.ру"
        description="Страница для авторизации клиентов сервиса Работа.ру в CRM для рекрутеров СберПодбор"
        meta={[
          {
            property: 'og:title',
            content: 'Авторизация в сервисе СберПодбор для клиентов Работа.ру'
          },
          {
            property: 'og:description',
            content: 'Страница для авторизации клиентов сервиса Работа.ру в CRM для рекрутеров СберПодбор'
          }
        ]}
      />

      <header>
        <div className={s.authPageContainer}>
          <div className={s.headerContainer}>
            <LogoRabotaru />
            <LogoSeparator className={s.headerLogoSeparator} />
            <a
              href="/"
              className={s.headerLogoLink}
            >
              <Logo
                alt="СберПодбор"
              />
            </a>
          </div>
        </div>
      </header>

      <div className={s.hero}>
        <div className={s.authPageContainer}>
          <div className={s.heroContainer}>
            <div className={s.heroLeft}>
              <h1 className={s.heroTitle}>Вам доступна CRM для рекрутеров СберПодбор</h1>
              <ul className={s.heroList}>
                <li className={s.heroListItem}>Вход по логину и паролю от Работа.ру</li>
                <li className={s.heroListItem}>Бесплатный базовый функционал</li>
                <li className={s.heroListItem}>Входит в реестр российского ПО</li>
              </ul>
              <a
                onClick={onRegistrationBtnClick}
                href={linkToSignIn}
                className={cn(s.btn, s.heroBtn)}
                target='_blank'
              >
                Создать аккаунт
              </a>
              <p className={s.heroCaption}>
                Создавая аккаунт в СберПодборе с помощью сервиса Работа.ру, вы принимаете <a href="/doc/Соглашение_оферта_об_использовании_сервиса_СберПодбор.pdf" className="text-blue">условия Соглашения-Оферты</a> и даете согласие на <a href="/doc/Политика_обработки_персональных_данных.pdf" className="text-blue">обработку персональных данных</a>
              </p>
            </div>
            <div className={s.heroSlider}>
              {isLoaded && <SlickSlider {...sliderSettings}>
                <div className={s.heroSlide}>
                  <picture>
                    <source srcSet="/images/auth-rabotaru/hero-slide-1.png" media="(max-width: 719px)" />
                    <source srcSet="/images/auth-rabotaru/hero-slide-1.gif"/>
                    <img src='/images/auth-rabotaru/hero-slide-1.gif' className={s.heroSlideImg} />
                  </picture>
                </div>
                <div className={s.heroSlide}>
                  <picture>
                    <source srcSet="/images/auth-rabotaru/hero-slide-2.png" media="(max-width: 719px)" />
                    <source srcSet="/images/auth-rabotaru/hero-slide-2.gif"/>
                    <img src='/images/auth-rabotaru/hero-slide-2.gif' className={s.heroSlideImg} />
                  </picture>
                </div>
                <div className={s.heroSlide}>
                  <picture>
                    <source srcSet="/images/auth-rabotaru/hero-slide-3.png" media="(max-width: 719px)" />
                    <source srcSet="/images/auth-rabotaru/hero-slide-3.gif"/>
                    <img src='/images/auth-rabotaru/hero-slide-3.gif' className={s.heroSlideImg} />
                  </picture>
                </div>
              </SlickSlider>}
            </div>
          </div>
        </div>
      </div>

      <div className={s.request}>
        <div className={s.authPageContainer}>
          <div className={s.requestContainer}>
            <p className={s.requestText}>Если вы уже пользуетесь СберПодбором и хотите связать его с личным кабинетом в сервисе Работа.ру, обратитесь в техподдержку</p>
            <button className={cn(s.btn, s.requestBtn)} onClick={openModal}>Оставить заявку</button>
          </div>
        </div>
      </div>

      <div className={s.helps}>
        <div className={s.authPageContainer}>
          <h2 className={cn(s.title, s.helpTitle)}>СберПодбор помогает</h2>
          <div className={s.helpsList}>
            <div className={cn(s.helpsItem, s.helpsItem1)}>
              <h4 className={s.helpItemTitle}>Создавать и размещать вакансии</h4>
              <p className={s.helpItemText}>Заполните несложную форму создания вакансии и разместите ее на сайтах по трудоустройству прямо из CRM, не переключаясь между вкладками.</p>
              <img src='/images/auth-rabotaru/help-screen-1.png' className={s.helpItemImg} />
            </div>
            <div className={s.helpsItem}>
              <h4 className={s.helpItemTitle}>Управлять базой кандидатов</h4>
              <p className={s.helpItemText}>Загружайте резюме так, как удобно вам, автоматически исключайте дубли и легко находите нужных соискателей в базе.</p>
              <img src='/images/auth-rabotaru/help-screen-2.png' className={s.helpItemImg} />
            </div>
            <div className={s.helpsItem}>
              <h4 className={s.helpItemTitle}>Эффективно общаться с заказчиками и кандидатами</h4>
              <p className={s.helpItemText}>Получайте заявки на подбор персонала и обсуждайте кандидатов прямо в CRM. Общайтесь с кандидатами в мессенджерах, не выходя из СберПодбора.</p>
              <img src='/images/auth-rabotaru/help-screen-3.png' className={s.helpItemImg} />
            </div>
            <div className={s.helpsItem}>
              <h4 className={s.helpItemTitle}>Контролировать эффективность подбора персонала</h4>
              <p className={s.helpItemText}>Создавайте рекрутинговые отчеты в 1 клик, находите с их помощью точки роста для вашего рекрутинга.</p>
              <img src='/images/auth-rabotaru/help-screen-4.png' className={s.helpItemImg} />
            </div>
          </div>
        </div>
      </div>

      <div className={cn(s.benefits, s.authPageContainer)}>
        <h2 className={cn(s.title)}>Преимущества СберПодбора</h2>
        <div className={s.benefitsList}>
          <div className={cn(s.benefitsItem)}>
            <div className={cn(s.benefitsItemContent)}>
              <h5 className={s.benefitsItemTitle}>Доступность</h5>
              <p className={s.benefitsItemText}>Чтобы начать работу, нажмите «Создать аккаунт» и введите в форму ваши логин и пароль на Работа.ру.</p>
            </div>
            <div className={s.benefitsItemRequest}>
              <a
                onClick={onRegistrationBtnClick}
                href={linkToSignIn}
                className={cn(s.btn, s.benefitsItemRequestBtn)}
                target='_blank'
              >
                Создать аккаунт
              </a>
              <p className={s.benefitsItemRequestCaption}>
                Создавая аккаунт в СберПодборе с помощью сервиса Работа.ру, вы принимаете <a href="/doc/Соглашение_оферта_об_использовании_сервиса_СберПодбор.pdf" className="text-blue">условия Соглашения-Оферты</a> и даете согласие на <a href="/doc/Политика_обработки_персональных_данных.pdf" className="text-blue">обработку персональных данных</a>
              </p>
            </div>
          </div>
          <div className={s.benefitsItem}>
            <div className={s.benefitsItemContent}>
              <h5 className={s.benefitsItemTitle}>Бесплатный базовый функционал</h5>
              <p className={s.benefitsItemText}>Ведите базу кандидатов, создавайте воронки найма, отслеживайте их эффективность и узкие места.</p>
            </div>
          </div>
          <div className={s.benefitsItem}>
            <div className={s.benefitsItemContent}>
              <h5 className={s.benefitsItemTitle}>Оперативная техподдержка</h5>
              <p className={s.benefitsItemText}>Персональный менеджер будет на связи и поможет решить любые вопросы, связанные с работой в системе.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={s.footer}>
        <div className={s.authPageContainer}>
          <div className={s.footerContainer}>
            <div className={cn(s.footerMain, s.footerColumn)}>
              <LogoWhite className={s.footerLogo}/>
              <p className={s.footerDesc}>
                СберПодбор — система, которая позволит сделать прозрачной вашу систему найма
              </p>
            </div>

            <div className={s.foterNavs}>
              <div className={cn(s.foterNav, s.footerColumn)}>
                <span
                  className={cn(s.foterNavTitle, { [s.foterNavTitleExpanded]: Boolean(footerBlockHeight.contacts) })}
                  onClick={() => toggle('contacts')}
                >
                  Контакты
                </span>
                <AnimateHeight
                  height={footerBlockHeight.contacts}
                  aria-hidden={Boolean(footerBlockHeight.contacts)}
                  duration={250}
                >
                  <ul className={s.footerNavList}>
                    <li className={s.footerNavItem}>
                      <a href='mailto:sales@sberpodbor.ru'>sales@sberpodbor.ru</a>
                    </li>
                    <li className={s.footerNavItem}>
                      <a href='tel:+7 495 139-35-59'>+7 495 139-35-59</a>
                    </li>
                    <li className={s.footerNavItem}>
                      Адрес: 109004, г. Москва, Малый Дровяной переулок., дом 3, строение 1, помещение II, комната 32
                    </li>
                  </ul>
                </AnimateHeight>
              </div>

              <div className={cn(s.foterNav, s.footerColumn)}>
                <span
                  className={cn(s.foterNavTitle, { [s.foterNavTitleExpanded]: Boolean(footerBlockHeight.links) })}
                  onClick={() => toggle('links')}
                >
                  Важное
                </span>
                <AnimateHeight
                  height={footerBlockHeight.links}
                  aria-hidden={Boolean(footerBlockHeight.links)}
                  duration={250}
                >
                  <ul className={s.footerNavList}>
                    <li className={s.footerNavItem}>
                      <a href='/doc/Соглашение_оферта_об_использовании_сервиса_СберПодбор.pdf' className={s.footerNavLink}>Договор-оферта</a>
                    </li>
                    <li className={s.footerNavItem}>
                      <a href='/doc/Политика_обработки_персональных_данных.pdf' className={s.footerNavLink}>Политика обработки персональных данных</a>
                    </li>
                    <li className={s.footerNavItem}>
                      <a href='/doc/Политика_информационной_безопасности_сервиса.pdf' className={s.footerNavLink}>Политика информационной безопасности</a>
                    </li>
                  </ul>
                </AnimateHeight>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RequestModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className='tariffs-modal'
        formName={'auth-rabotaru'}
      />
    </div>
  );
};

export default RabotaruAuth;
